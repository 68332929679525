FormController.$inject = ['logService', '$scope', 'allevaApi', '$timeout', 'noty', '$interval'];
export default function FormController(logService, $scope, allevaApi, $timeout, noty, $interval) {
    /***************************
     * Params
     **************************/


    /***************************
     * Init
     **************************/
    $scope.initForm = () => {
        $scope.parentController = $scope.$ctrl.parent;
    }

    $scope.initFormView = () => {
        $scope.parentController = $scope.$ctrl.parent;
    }

    /***************************
     * Actions
     **************************/
    $scope.validateSubmit = (form) => {
        if(!form.$valid){
            let alert = "<h5>Incomplete Application</h5><p>Please fill out all required fields.</p>";
            new noty({
            text: alert
            }).show();
            return false;
        }
        return true;
    };

    $scope.closeApplicationClicked = (timer = null) => {

        if(timer){
            $interval.cancel(timer);
           
        }

        $scope.parentController.closeForm();        

    };

    

    /***************************
     * CRUD
     **************************/

    
    /***************************
     * Helpers
     **************************/
}
