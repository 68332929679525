TreatmentPlanController.$inject = ['$scope', 'allevaApi', 'noty'];
export default function TreatmentPlanController($scope, allevaApi, noty) {
    /***************************
     * Params
     **************************/
     $scope._TPRSectionDemo_Graphy      = 1001;
     $scope._TPRSectionDischarge_Planning   = 1006;
     $scope._TPRSectionMaster_Problem   = 1003;
     $scope._TPRSectionTreatment_Plan   = 1008;
     $scope._TPRSectionSNAG             = 1004;
     $scope._TPRSectionSummary_Progress = 1005;
     $scope._TPRSectionJustification_Treatment    = 1007;
     $scope._TPRSectionTPR_Note         = 1009;
     $scope._TPRSectionTPR_Next_Review  = 1010;
     $scope._TPRSectionMedications      = 1012;

     $scope._FamilyMemberType           = 2500;

    /***************************
     * Init
     **************************/
   

    /***************************
     * Actions
     **************************/
    $scope.validateSubmit = (form) => {
        if(!form.$valid){
            let alert = "<h5>Incomplete Application</h5><p>Please fill out all required fields.</p>";
            new noty({
            text: alert
            }).show();
            return false;
        }
        return true;
    };

    $scope.signatureEntered = (result) => {
        submitSignature(result.participantSignature, result.type)
        .then(_ => {
            $scope.$ctrl.parent.closeForm();
        })
        .catch(_ => {
                let alert = "<h5>Error</h5><p>Could not submit signature. Please try again</p>";
                new noty({
                text: alert
            }).show();
            
        })
        .finally(_ => {
            result.finish();
        });
    }

    $scope.reviewSignatureEntered = (result) => {
        submitReviewSignature(result.participantSignature)
        .then(_ => {
            $scope.$ctrl.parent.closeForm();
        })
        .catch(_ => {
                let alert = "<h5>Error</h5><p>Could not submit signature. Please try again</p>";
                new noty({
                text: alert
            }).show();
            
        })
        .finally(_ => {
            result.finish();
        });
    }
        

    

    /***************************
     * CRUD
     **************************/
    // POST
    const submitSignature = (signature, type) => {
        let filters = {
        "tpid": $scope.$ctrl.parent.currentForm.id,
        "entityId": $scope.$ctrl.parent.currentApplicant.clientId,
        "data": signature,
        "type": type
        };

        return allevaApi.TreatmentPlan.submitSignature(filters)
        .then((response) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
    };

    const submitReviewSignature = (signature) => {
        let filters = {
        "tprid": $scope.$ctrl.parent.currentForm.tpReviewOverview.treatmentPlanReviewId,
        "entityId": $scope.$ctrl.parent.currentApplicant.clientId,
        "data": signature
        };

        return allevaApi.TreatmentPlan.submitReviewSignature(filters)
        .then((response) => {
            return Promise.resolve(response);
        }).catch((error) => {
            return Promise.reject(error);
        });
    };
    
    /***************************
     * Helpers
     **************************/
}