// TODO: move module out so we can use in allevaApp
/*jshint esversion: 6 */

import angular from 'angular';
import Noty from 'noty';

// css
import 'noty/lib/noty.css';
import 'noty/lib/themes/bootstrap-v3.css';

const apiServerError         = '<h5>Server Error</h5> <p>Please try again later. If the error persists, please contact our support team for assistance.</p>';
const apiBadRequestError     = '<h5>Bad Request Error</h5> <p>Please try again later. If the error persists, please contact our support team for assistance.</p>';
const apiAuthenticationError = "<h5>Session Expired</h5> <p>Please sign in. If this error persists, please contact our support team for assistance.</p>";

function AllevaApi($http, noty) {
    const serviceBase = ALLEVA_API_HOST;   // injected by webpack
    const authBase    = `${ALLEVA_AUTH_HOST}/auth`;

    // function to wrap gets so we handle everything consistently
    const httpGet = function (url, params, secure = true) {

        var req = {
            method: 'GET',
            url   : url,
            params: params,
            secure: secure
        };

        return $http(req)
         .then(function(response) {
            return response.data;
        }).catch(function(e) {
            allevaError(e);
        }).finally(function() {
            // do work
        });

    };

    const httpPost = function (url, params, secure = true) {

        var req = {
            method : 'POST',
            url    : url,
            headers: {
              'Content-Type': "application/json"
            },
            data  : params,
            secure: secure
        };

        return $http(req)
         .then(function(response) {
            return response.data;
        }).catch(function(e) {
            allevaError(e);
        }).finally(function() {
            // do work
        });
    };

    const httpPut = function (url, params, secure = true) {

        var req = {
            method : 'PUT',
            url    : url,
            headers: {
              'Content-Type': "application/json"
            },
            data  : params,
            secure: secure
        };

        return $http(req)
         .then(function(response) {
            return response.data;
        }).catch(function(e) {
            allevaError(e);
        }).finally(function() {
            // do work
        });
    };

    const allevaError = (e) => {
        console.info('Error: ', e);

        // TODO refactor: capture and log information about the error that occurred
        switch(e.status) {
            case 400:   // Bad request
                new noty({ text: apiBadRequestError, type: 'error' }).show();
                break;
            case 401:   // Unauthorized
                new noty({ text: apiAuthenticationError, type: 'error' }).show();
                break;
            case 403:   // Forbidden
                if(e.data.errors){
                    e.data.errors.forEach(error => {
                        new noty({ text: error, type: 'error'}).show();
                    });
                }
                else if (typeof(e.data) === 'string' || e.data instanceof String) {
                    new noty({ text: e.data, type: 'error'}).show();
                }
                break;
            case 409:   // Conflict
                let conflictText = e.data;
                new noty({ text: conflictText, type: 'error'}).show();

                break;
            case 417:   // Expectation Failed
                let statusText = e.data;
                new noty({ text: statusText, type: 'error'}).show();

                break;
            case 500:   // Server error
                new noty({ text: apiServerError, type: 'error' }).show();
                break;
            default:
                new noty({ text: apiServerError, type: 'error' }).show();
            }

        throw e;
    };

    // setup noty defaults
    noty.overrideDefaults({
        type   : 'error',
        theme  : 'bootstrap-v3',
        timeout: 5000
    });

    return {
        Auth: {
            login(filters) {
                return httpPost(`${authBase}/login/`, filters);
            },
            signup(filters) {
                return httpPost(`${authBase}/signup/`, filters);
            },
            forgotUsername(filters){
                return httpPost(`${authBase}/forgot-username/`, filters);
            },
            forgotPassword(filters){
                return httpPost(`${authBase}/forgot-password/`, filters);
            },
            resetPassword(filters){
                return httpPost(`${authBase}/reset-password/`, filters);
            },
            getAllRoles(filters){
                return httpGet(`${authBase}/roles`, filters, false);
            }
        },
        FamilyAPI: {
            health(filters) {
                return httpGet(`${serviceBase}/health`, filters, false);
            },

            createRehabUser(filters) {
                return httpPost(`${serviceBase}/family/create-user`, filters);
            },
            currentUserDetails(params) {
                const queryString = '?' + new URLSearchParams(params);
                return httpGet(`${serviceBase}/family/user-for-portal` + queryString, {});
            },
            getParticipantChecklist(filters){
                return httpGet(`${serviceBase}/family/participant-checklist`, filters)
            },
            getParticipantChecklistBadgeCount(filters){
                return httpGet(`${serviceBase}/family/participant-checklist-badge`, filters)
            },
            getForms(filters){
                return httpGet(`${serviceBase}/family/checklist`, filters);
            },
            getClients(params) {
                const queryString = '?' + new URLSearchParams(params);
                return httpGet(`${serviceBase}/family/clients` + queryString, {});
            },
            createProspect(filters) {
                return httpPost(`${serviceBase}/family/create-prospect`, filters);
            },
            getStyles(filters) {
                const queryString = '?' + new URLSearchParams(filters);
                return httpPost(`${serviceBase}/family/styles` + queryString, {});
            },
            getClientUpcomingSessions(filters) {
                return httpGet(`${serviceBase}/family/upcoming-sessions`, filters);
            },
            getClientImagePath(fileName) {
                return `${serviceBase}/v2/images/clients/` + fileName;
            }
        },
        CommonAPI: {
            rehabDetails(params) { // Un-authenticated call
                const queryString = '?' + new URLSearchParams(params);
                return httpGet(`${serviceBase}/common/rehab-details` + queryString, {}, false);
            },
            countries() {
                return httpGet(`${serviceBase}/common/countries`, {});
            },
            states(params) {
                const queryString = '?' + new URLSearchParams(params);
                return httpGet(`${serviceBase}/common/states` + queryString, {});
            },
            relationships() {
                return httpGet(`${serviceBase}/common/relationships`, {});
            },
        },
        Forms: {
            submitForm(filters) {
                return httpPost(`${serviceBase}/family/submit-form`, filters);
            }
        },
        AdvancedForms: {
            submitForm(formId, leadId, dataValues) {
                return httpPost(`${serviceBase}/advanced-forms/${formId}/data/${leadId}`, {'DataValues': dataValues});
            },
            getAdvancedForm(advancedFormId){
                return httpGet(`${serviceBase}/advanced-forms/${advancedFormId}/Published`)
            },
            AdvancedFormElements: {
                getElementsForFormId(formId){
                    return httpGet(`${serviceBase}/advanced-form-elements?advancedFormId=${formId}`)
                }
            },
            DataFacets:{
                search(params){
                    return httpPost(`${serviceBase}/data-facets/search`, params)
                }
            },
            DataValues:{
                search(params) {
                    return httpPost(`${serviceBase}/data-values/search`, params)
                },

                save(id, value){
                    return httpPut(`${serviceBase}/data-values/${id}`, value)
                }
            }
        },
        TreatmentPlan: {
            submitSignature(filters) {
                return httpPost(`${serviceBase}/treatment-plans/` + filters.tpid + `/signature`, filters);
            },
            submitReviewSignature(filters) {
                return httpPost(`${serviceBase}/treatment-plans/` + filters.tprid + `/review-signature`, filters);
            }
        },
        Messages: {
            getPortalEmails(filters){
                return httpGet(`${serviceBase}/family/portal-emails`, filters);
            },
            getEmailMessage(filters){
                return httpGet(`${serviceBase}/family/email-message`, filters);
            },
            readEmail(params) {
                const queryString = '?' + new URLSearchParams(params);
                return httpPost(`${serviceBase}/family/read-email` + queryString, {});
            },
            sendEmail(filters) {
                return httpPost(`${serviceBase}/family/send-message-to-emr`, filters);
            },
            getAttachmentPath(fileName) {
                return `${serviceBase}/v2/documents/attachments/` + fileName;
            }
        },
        Classrooms: {
            isAuthenticated() {
                return httpGet(`${serviceBase}/classrooms/is-authenticated`, {}, true);
            },
            getClientCourses(userId) {
                const queryString = '?' + new URLSearchParams(userId);
                return httpGet(`${serviceBase}/classrooms/client-courses` + queryString, {}, true);
            },
            inviteGuardian(invite) {
                return httpPost(`${serviceBase}/classrooms/invite-guardian`, invite, true);
            },
        },
        Clients: {
            getDoc(leadId) {
                return httpGet(`${serviceBase}/clients/` + leadId + `/documents`, {}, true);
            },
            saveDoc(leadId, params) {
                return httpPost(`${serviceBase}/clients/` + leadId + `/documents`, params, true);
            },
            deleteDoc(leadId, docId) {
                return httpDelete(`${serviceBase}/clients/` + leadId + `/documents/` + docId, {}, true);
            },
        },
        MasterCodes: {
            search(params) {
                return httpPost(`${serviceBase}/master-codes/search`, params);
            }
        }

    };
}

export default angular.module('alleva.api', [])
    .service('allevaApi', ['$http', 'noty', AllevaApi])
    .config(['$httpProvider', function ($httpProvider) {
        // send the cookie from allevasoft with every request to authenticate
        //$httpProvider.defaults.withCredentials = true;
    }])
    .constant('noty', Noty)
    .name;
