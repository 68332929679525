AdvancedFormController.$inject = ['$element', 'allevaApi', /*'darkDeploy',*/ '$scope', '$rootScope', 'allevaAutosave', 'dayjs', 'noty'];
export default function AdvancedFormController($element, allevaApi, /*darkDeploy,*/ $scope, $rootScope, allevaAutosave, dayjs, noty) {


        /***************************
         * Properties
         **************************/
        const ctrl = this;
        
        // Flags
        ctrl.saved        = false;
        ctrl.fromAutosave = false;
        ctrl.initialized  = false;

        // Data
        ctrl.registeredEvents = [];
        ctrl.formDataFacets   = [];
        ctrl.formDataValues   = [];
        ctrl.$element         = $element;

        // Definitions
        const DataKind = { Integer: "Integer", Boolean: "Boolean", Text: "Text", 
            Decimal: "Decimal", Image: "Image", File: "File", DateTime: "DateTime", Signature: "Signature", };

            
        /***************************
         * Init
         **************************/
        async function initializeData() {

            /*****************************************************************
             *  DEVELOPMENT ONLY ENVIRONMENT */
            // We don't want AdvancedForm being initiated if FormId is not defined.


            // if(darkDeploy.staging){
            //     if(typeof ctrl.advancedFormId === 'undefined'){
            //         // TODO
            //         // toastr.warning("Make sure a FormId is being passed to AdancedForm.", "WARNING:");
            //     }
            // }


            /*****************************************************************/

            const dataAndFacets = await getAdvancedFormData(ctrl.advancedFormId);

            // TODO upgrade webpack
            ctrl.formDataValues     = dataAndFacets ? dataAndFacets.dataValues || [] : [];
            ctrl.formDataFacets     = dataAndFacets ? dataAndFacets.dataFacets || [] : [];

            ctrl.callEventHandler();
            ctrl.initialized = true;
        }

        ctrl.$onInit = function () {

        };

        ctrl.$postLink = async function () {
            await initializeData();
            ctrl.initializeScopes();
            ctrl.setComponentValues();

            if (ctrl.formObject)
                ctrl.formObject.IsCompleted = isCompleted();

            if (!ctrl.isEditable()) {
                ctrl.setReadOnlyValues();
            }
        }

        ctrl.$doCheck = function () {
            
        }

        /***************************
         * Actions
         **************************/
        ctrl.refresh = async () => {
            await initializeData();
        }

        ctrl.isEditable = () => {
            return ctrl.formMode != 'readonly';
        }

        /***************************
         * Event Handlers
         **************************/
        ctrl.registerEventHandler = (event) => {
            ctrl.registeredEvents.push(event);

            // TODO: Create different event handlers
            // For initialized data
            if(ctrl.initialized){
                ctrl.callEventHandler();
            }
        }

        ctrl.callEventHandler = () => {
            ctrl.registeredEvents.forEach(handler => {
                handler();
            });
        }

        $scope.$on("AdvancedForm.requestSave", (e, markCompleted) => { 
            ctrl.submit(markCompleted);
        });

        $scope.$on("FormDesigner.didSave", () => { 
            // handler code here 
            ctrl.refresh();
        });

        $rootScope.$on("AllevaAutosave.autosave", () => { 
            // Autosave triggered

        /*
            if(ctrl.isDirty){
                ctrl.fromAutosave = true;
                ctrl.submit();
                ctrl.isDirty = false;
            }
            */
        });

        const eventListenerTriggers = () => {
            ctrl.isDirty = true; // Make input dirty.

            // TODO: restore
            //allevaAutosave.trigger(); // map the autosave trigger to this event listener.
        }

        /***************************
         * GET
         **************************/
        ctrl.getComponentValueFromData = function (uuid) {

            const facet = ctrl.formDataFacets.filter(dataFacet => {
                return dataFacet.uuid == uuid;
            });

            if (!facet)
                return null;

            var data = ctrl.formDataValues.filter(dataValue => {
                return dataValue.dataFacetId == facet.id;
            });

            if (!data)
                return null;

            return getValueForKind(data, facet.dataKind);
        };

        ctrl.getComponentValueFromForm = function (uuid) {
            const inputs = getInputs(uuid);
            let   result = "";

            // TODO: stop looping through inputs for every component value
            inputs.forEach(input => {
                
                let type = getType(input);
                switch (type) {
                    case "checkbox": {
                        if (input.checked) {
                            if (result) {
                                result += `,${input.value}`;
                            }
                            else {
                                result += input.value;
                            }
                        }
                        break;
                    }
                    case "radio":
                        if (input.checked) {
                            result = input.value;
                        }
                        break;
                    case "signature":
                        // TODO: get via angular component
                        result = angular.element(input).jSignature('getData');
                        break;
                    case "time":
                        if(input.value)
                            result = dayjs(input.value, 'HH.mm').utc().format('HH:mm');
                        break;
                    case "file":
                        result = angular.element(input).scope().$ctrl.getValue();
                        break;
                    default: 
                        result = input.value;
                        break;
                }
            });

            return result;
        };

        function getType(input) {
            if (input.type)
                return input.type;

            if (input.tagName == "SIGNATURE")
                return "signature";

            if (input.tagName == "SELECT")
                return "dropdown";

            // TODO: handle error condition
        }

        ctrl.getFormComponents = function () {
            return ctrl.formDataFacets;
        }

        function getInputs(uuid) {
            let selector = `[data-guid="${uuid.toLowerCase()}"] input, [data-guid="${uuid.toLowerCase()}"] textarea, [data-guid="${uuid.toLowerCase()}"] select, [data-guid="${uuid.toLowerCase()}"] signature, [data-guid="${uuid.toLowerCase()}"] fileupload`;
            return ctrl.$element[0].querySelectorAll(selector);
        }

        ctrl.setComponentValues = function () {
            const dataFacetMap = new Map(ctrl.formDataFacets.map(facet => [facet.id, facet]));
            ctrl.formDataValues.forEach(dataValue => {
                const facet = dataFacetMap.get(dataValue.dataFacetId);

                if (facet) {
                    const inputs = getInputs(facet.uuid);
                    const value  = getValueForKind(dataValue, facet.dataKind);

                    inputs.forEach(input => {

                        // Register an on-chnge event for components
                        input.addEventListener("input", eventListenerTriggers); 

                        let type = getType(input);

                        switch (type) {
                            case "checkbox":
                                if (value) {
                                    const splitValues = value.split(",");
                                    input.checked = splitValues.includes(input.value);
                                }
                                break;
                            case "radio":
                                input.checked = (input.value == value);
                                break;
                            case "time":
                                if(value)
                                    input.value = dayjs.utc(value).local().format('HH:mm');
                                break;
                          case "date":
                                if (value)
                                  input.value = (new Date(value)).toISOString().substring(0, 10);;
                                break;
                            case "signature":
                                // TODO: set via angular component
                                angular.element(input).jSignature('setData', value);
                                break;
                            case "file":
                                angular.element(input).scope().$ctrl.setValue(value);
                                break;
                            default:
                                input.value = value;
                                break;
                        }
                    });                    
                }
            });
        }

        ctrl.initializeScopes = function () {
            // hacky
            ctrl.formDataFacets.forEach(facet => {
                const inputs = getInputs(facet.uuid);
                inputs.forEach(input => {
                    let type = getType(input);
                    switch (type) {
                        case "file":
                            angular.element(input).scope().$ctrl.leadId = this.leadId;
                            break;
                    }
                });
            });
        }

        ctrl.setReadOnlyValues = function () {
            ctrl.formDataFacets.forEach(facet => {
                const inputs = getInputs(facet.uuid);

                inputs.forEach(input => {
                    input.readOnly = true;
                    input.disabled = true;
                });               
            });
        }

        function getAdvancedFormDataFacets(formId) {

            return allevaApi.AdvancedForms.AdvancedFormElements.getElementsForFormId(formId)
                .then(advancedFormElements => {
                    if (!advancedFormElements){ 
                        // this form has no links to DataFacets
                        return;
                    }

                    const dataFacetIds = advancedFormElements.map(element => element.dataFacetId);
                    return allevaApi.AdvancedForms.DataFacets.search(dataFacetIds)
                    .then(dataFacets => {
                        return dataFacets;
                    })
                });
        }

        function getAdvancedFormData(formId = null) {

            const id = (formId !== null && formId !== undefined) ? formId : ctrl.advancedFormId;
            return getAdvancedFormDataFacets(id)
                .then((dataFacets) => {

                    if (!dataFacets)
                        return;

                    const data = {
                        leadId      : ctrl.leadId,
                        datafacetIds: dataFacets.map(facet => facet.id)
                    };

                   return allevaApi.AdvancedForms.DataValues.search(data)
                        .then((dataValues) => {
                            return {
                                dataValues: dataValues,
                                dataFacets: dataFacets
                            };
                        });
                });
        }

        /***************************
         * Saves
         **************************/
        ctrl.submit = function (markCompleted) {
            // TODO: validation
            let promise = saveData()
            .then((result) => {
                ctrl.saved = true;

                if(ctrl.fromAutosave){
                    let alert = "<h5>Dashboard</h5><p>Form autosaved.</p>";
                    new noty({
                        text: alert,
                        timeout: 2000,
                        type: 'success'
                    }).show();

                    ctrl.fromAutosave = false;
                }
                else{
                    let alert = "<h5>Form data saved successfully.</p>";
                    new noty({
                        text: alert,
                        timeout: 2000,
                        type: 'success'
                    }).show();

                    ctrl.formObject.IsCompleted = isCompleted();
                }

                $scope.$emit("AdvancedForm.saveComplete", ctrl.saved);
            })
            .catch((error) => {
                let alert = "<h5>Error saving form data.</p>";
                    new noty({
                        text: alert,
                        timeout: 8000,
                        type: 'error'
                    }).show();

                ctrl.saved = false;
                $scope.$emit("AdvancedForm.saveComplete", ctrl.saved, error);
              });
        }

        const saveData = () => {

            const data = ctrl.formDataFacets.map(dataFacet => {

                const dataValue = ctrl.getComponentValueFromForm(dataFacet.uuid);

                // TODO: handle null dataValue

                const dataValueId =  dataValue ? dataValue.id : null;
                const item  = {
                    Id            : dataValueId,
                    DataFacetId   : dataFacet.id,
                    IntegerValue  : dataFacet.dataKind == DataKind.Integer ? dataValue  : null,
                    StringValue   : dataFacet.dataKind == DataKind.Text ? dataValue     : null,
                    DateTimeValue : dataFacet.dataKind == DataKind.DateTime ? dataValue : null,
                    DecimalValue  : dataFacet.dataKind == DataKind.Decimal ? dataValue  : null,
                    ImageValue    : dataFacet.dataKind == DataKind.Image ? dataValue    : null,
                    FileValue     : dataFacet.dataKind == DataKind.File ? dataValue     : null,
                    SignatureValue: dataFacet.dataKind == DataKind.Signature ? dataValue: null,
                    BooleanValue  : dataFacet.dataKind == DataKind.Boolean ? dataValue  : null,
                    leadId        : ctrl.leadId
                };

                return item;
            });

            return allevaApi.AdvancedForms.submitForm(ctrl.advancedFormId, ctrl.leadId, data);
        }
        
        /***************************
         * Helpers
         **************************/
        const getValueForKind = (data, kind) => {
            switch (kind) {
                case DataKind.Integer: 
                    return data.integerValue;
                case DataKind.Boolean: 
                    return data.booleanValue;
                case DataKind.Text: 
                    return data.stringValue;
                case DataKind.Decimal: 
                    return data.decimalValue;
                case DataKind.Image: 
                    return data.imageValue;
                case DataKind.File: 
                    return data.fileValue;
                case DataKind.DateTime: 
                    return data.dateTimeValue;
                case DataKind.Signature: 
                    return data.signatureValue;
            }
        }

        function isCompleted() {
            // TODO: iterate over required values
            return true;
        }

    };

